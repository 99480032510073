import React, { useState, useEffect } from 'react';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const CustomGallery = ({ images }) => {
  const [isGalleryAutoPlaying, setIsGalleryAutoPlaying] = useState(true);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setIsGalleryAutoPlaying(true);
    }, 5000);

    return () => {
      clearTimeout(delayTimer);
    };
  }, []);

  const imageObjects = images.map((imageUrl) => ({
    original: imageUrl,
    thumbnail: imageUrl,
    thumbnailWidth: 80,
    thumbnailHeight: 60,
    originalWidth: 800,
    originalHeight: 600,
  }));

  return (
    <div>
      <Gallery items={imageObjects} autoPlay={isGalleryAutoPlaying} slideInterval={4000} />
    </div>
  );
};

export default CustomGallery;
