import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Spin, Typography, Button, Collapse, Empty, Tabs } from 'antd';
import Gallery from './Gallery';
import './style.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const { Panel } = Collapse;
const { Text } = Typography;
const { Title } = Typography;

const URL = () => {
  const { id } = useParams();
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const headings = [
    'Introduction',
    'Why Choose Me',
    'Your Problem',
    'My Solution',
    'My Offer',
    'Conclusion',
  ];

  useEffect(() => {
    const fetchProposal = async () => {
      try {
        const response = await axios.get(`https://www.call.dymax.a2hosted.com/proposal/${id}`);
        setProposal(response.data);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching proposal:', error);
      }
    };

    fetchProposal();
  }, [id]);

  // Function to format the timestamp
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };

    const dateStr = date.toLocaleDateString('en-US', options);
    const timeStr = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    // Add emojis before date and time
    const calendarEmoji = '📅';
    const clockEmoji = '🕒';

    return `${calendarEmoji} ${dateStr} ${clockEmoji}`;
  };

  const mapSectionIdToHeading = (sectionId) => {
    switch (sectionId) {
      case 'intro':
        return 'Introduction';
      case 'choose':
        return 'Why Choose Me?';
      case 'problem':
        return 'Your Problem';
      case 'solution':
        return 'My Solution';
      case 'offer':
        return 'My Offer';
      case 'conclusion':
        return 'Conclusion';
    }
  };

  return (
    <Spin
      spinning={loading}
      fullscreen
      size="large"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      tip="Fetching the proposal for you... Please wait."
    >
      <div class="main-prop-inner">
        {/* Logo */}
        <img
          src="https://dymaxtech.com/wp-content/uploads/2023/11/Multiversesol-Logo.webp"
          alt="Company Logo"
          style={{
            display: 'block',
            margin: '0 auto',
            width: '220px',
            marginTop: '20px',
          }}
        />

        {/* Banner */}
        {proposal && (
          <div
            style={{
              backgroundColor: '#000000',
              padding: '30px',
              textAlign: 'center',
              marginTop: '20px',
              alignItems: 'center',
              position: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Title level={1} style={{ color: 'white' }}>
              {proposal.title}
            </Title>
            <a
              href="https://www.upwork.com/freelancers/~0141e8647e55fa948a"
              target="_blank"
              style={{
                position: 'fixed',
                right: '0%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                width: '84px',
                height: '110px',
              }}
            >
              <img
                src="https://i.ibb.co/BBDDJmt/Layer-2.png"
                alt="icon"
                class="upwork-logo-float"
                style={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                  display: 'block',
                  borderRadius: '50%',
                  boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.3)',
                }}
              />
            </a>
          </div>
        )}

        {/* Proposal Content */}
        {loading ? ( // Check loading state
          <div style={{ textAlign: 'center', marginTop: '86px' }}>
            <Spin
              tip="Loading Proposal..."
              size="large"
              style={{ fontSize: '19px', color: 'sky blue' }}
            />
          </div>
        ) : proposal ? (
          <div style={{ maxWidth: '1000px', margin: '20px auto', padding: '20px' }}>
            {/* headerImages*/}
            <div style={{ margin: '0 20px' }}>
              {proposal.headerImages && proposal.headerImages.length > 0 && (
                <Slider
                  {...{
                    infinite: true,
                    speed: 500,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    pauseOnHover: true,
                    pauseOnDotsHover: true,
                  }}
                >
                  {proposal.headerImages.map((image, index) => {
                    return (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`header-image-${index}`}
                          onClick={() => {
                            setIsOpen(true);
                            setPhotoIndex(index);
                          }}
                          style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            padding: '2px',
                            swipeToSlide: true,
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                    );
                  })}
                </Slider>
              )}
              {isOpen && (
                <Lightbox
                  mainSrc={proposal.headerImages[photoIndex]}
                  nextSrc={proposal.headerImages[(photoIndex + 1) % proposal.headerImages.length]}
                  prevSrc={
                    proposal.headerImages[
                      (photoIndex + proposal.headerImages.length - 1) % proposal.headerImages.length
                    ]
                  }
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + proposal.headerImages.length - 1) % proposal.headerImages.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % proposal.headerImages.length)
                  }
                />
              )}

              {/* Render the proposal content with images */}
              <Tabs defaultActiveKey="1" tabPosition="left">
                {proposal.sections.map((section, index) => {
                  const heading = mapSectionIdToHeading(section.id);
                  return (
                    <Tabs.TabPane tab={heading} key={index + 1}>
                      <div>
                        <h2>{heading}</h2>
                        <div dangerouslySetInnerHTML={{ __html: section.content }} />
                      </div>
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            </div>

            {/* Display image gallery if there are images */}
            {proposal.image && proposal.image.length > 0 && (
              <div style={{ marginTop: '20px' }}>
                <Gallery images={proposal.image} />
              </div>
            )}
          </div>
        ) : (
          <p>No proposal found.</p>
        )}

        {/*CTA Section*/}
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <Title level={2}>WANT TO OUTSHINE YOUR COMPETITION?</Title>
          <p style={{ fontSize: '16px', marginBottom: '20px' }}>
            Reach out to me and take your first step in acheiving your digital goals.
          </p>
          <Button
            style={{
              size: 'large',
              transition: 'background-color 0.3s, transform 0.5s',
              color: 'white',
              padding: '10px 20px',
              fontSize: '18px',
            }}
            href="https://www.upwork.com/freelancers/~0141e8647e55fa948a"
            target="_blank"
            className="hover-effect-button"
          >
            <img src="https://e360digitalpro.com/wp-content/uploads/2023/11/upwork.png" /> Hire me!
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default URL;
