import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Menu, Dropdown, Modal, message } from 'antd';
import Notifications from '@/components/Notification';

import {
  AppstoreOutlined,
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  BellOutlined,
  DeleteOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import photo from '@/style/images/photo.png';
import { checkImage } from '@/request';

import { selectCurrentAdmin } from '@/redux/auth/selectors';
import history from '@/utils/history';
import uniqueId from '@/utils/uinqueId';

import { API_BASE_URL } from '@/config/serverApiConfig';
import { logout } from '@/redux/auth/actions';

const { confirm } = Modal;

export default function HeaderContent() {
  const dispatch = useDispatch();
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  const currentAdmin = useSelector(selectCurrentAdmin);
  //console.log('🚀 ~ file: index.jsx:23 ~ HeaderContent ~ currentAdmin:', currentAdmin);

  const showLogoutModal = () => {
    confirm({
      title: 'Are you sure you want to log out? ',
      icon: <LogoutOutlined />,
      content: 'Your session will be securely closed.',
      centered: true,

      onOk() {
        const audio = new Audio('/wrong-answer-129254.mp3');
        audio.play();
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            // Dispatch the logout action
            dispatch(logout());
            resolve();
          }, 1000);
        })
          .then(() => {
            message.success('Logout successful!');
          })
          .catch(() => {
            message.error('Logout failed!');
          });
      },
      onCancel() {
        // Handle the "Cancel" button click
      },
    });
  };

  const srcImgProfile = checkImage(API_BASE_URL + currentAdmin?.photo)
    ? API_BASE_URL + currentAdmin?.photo
    : photo;

  const profileDropdown = (
    <div className="profileDropdown whiteBox shadow" style={{ minWidth: '200px' }}>
      <div className="pad15" onClick={() => history.push('/admin')} style={{ cursor: 'pointer' }}>
        <Avatar
          size="large"
          className="last"
          src={srcImgProfile}
          style={{ color: '#eef2f4', backgroundColor: '#1c99f5' }}
        >
          {currentAdmin?.name.toUpperCase()}
        </Avatar>
        <div className="info">
          <p className="strong">
            {currentAdmin?.name} {currentAdmin?.surname}
          </p>
          <p>{currentAdmin?.email}</p>
        </div>
      </div>

      {/* <div>
        <Menu>
          <Menu.Item
            icon={<SettingOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/admin')}
          >
            Profile Settings
          </Menu.Item>
          <Menu.Item
            icon={<SettingOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/settings/')}
          >
            App Settings
          </Menu.Item>
        </Menu>
      </div> */}

      <div>
        <Menu>
          <Menu.Item icon={<AuditOutlined />} onClick={() => history.push('/activity-scorecard')}>
            {' '}
            View Scorecard
          </Menu.Item>
        </Menu>
      </div>
      <div>
        <Menu>
          <Menu.Item icon={<LogoutOutlined />} key={`${uniqueId()}`} onClick={showLogoutModal}>
            Logout
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <div
      className="headerIcon"
      style={{ position: 'absolute', right: 0, zIndex: '99', cursor: 'pointer' }}
    >
      <Dropdown overlay={profileDropdown} trigger={['click']} placement="bottomRight">
        {/* <Badge dot> */}
        <Avatar
          className="last"
          src={srcImgProfile}
          style={{ color: '#eef2f4', backgroundColor: '#1c99f5' }}
        >
          {currentAdmin?.name.charAt(0).toUpperCase()}
        </Avatar>
      </Dropdown>
    </div>
  );
}

//  <Avatar icon={<AppstoreOutlined />} />
// <Dropdown overlay={<Notifications />} trigger={['click']} placement="bottomRight">
//   {/* <Badge dot> */}
//   <Avatar icon={<BellOutlined />} />

//   {/* </Badge> */}
// </Dropdown>;
