import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useAppContext } from '@/context/appContext';
import logoIcon from '@/style/images/logo-icon.png';
import logoText from '@/style/images/logo-text.png';

import {
  DesktopOutlined,
  SettingOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  DashboardOutlined,
  TeamOutlined,
  UserOutlined,
  CreditCardOutlined,
  BankOutlined,
  InboxOutlined,
  ProjectTwoTone,
  DashboardTwoTone,
  IdcardTwoTone,
  CustomerServiceTwoTone,
  MessageTwoTone,
  ContainerTwoTone,
  ApiTwoTone,
  HourglassTwoTone,
  ContactsTwoTone,
  BuildTwoTone,
  BugTwoTone,
  ReconciliationTwoTone,
} from '@ant-design/icons';
import { selectCurrentAdmin } from '@/redux/auth/selectors';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation() {
  const currentAdmin = useSelector(selectCurrentAdmin);
  const { state: stateApp, appContextAction } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const { navMenu } = appContextAction;
  const [showLogoApp, setLogoApp] = useState(isNavMenuClose);

  useEffect(() => {
    if (isNavMenuClose) {
      setLogoApp(isNavMenuClose);
    }
    const timer = setTimeout(() => {
      if (!isNavMenuClose) {
        setLogoApp(isNavMenuClose);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [isNavMenuClose]);
  const onCollapse = () => {
    navMenu.collapse();
  };

  const specificAdminId = '6591d57a913b9f2caaedbcd5';

  // console.log('Admin id',currentAdmin._id)

  return (
    <>
      <Sider
        collapsible
        collapsed={isNavMenuClose}
        onCollapse={onCollapse}
        className="navigation custom-sider"
      >
        <div className="logo">
          <img
            src={logoIcon}
            alt="Logo"
            // style={{ margin: "0 auto 40px", display: "block" }}
          />

          {!showLogoApp && (
            <img src={logoText} alt="Logo" style={{ marginTop: '3px', marginLeft: '10px' }} />
          )}
        </div>
        <Menu mode="inline">
          <Menu.Item key={'Dashboard'} icon={<DashboardTwoTone />}>
            <Link to={'/'} />
            Dashboard
          </Menu.Item>

          {/* <Menu.Item key={'Customer'} icon={<CustomerServiceTwoTone />}>
            <Link to={'/lead'} />
            Leads
          </Menu.Item> */}

          <Menu.Item key={'Invoice'} icon={<MessageTwoTone />}>
            <Link to={'/invoice'} />
            Inbox
          </Menu.Item>

          <SubMenu
            key={'Marketing Tools'}
            icon={<ReconciliationTwoTone />}
            title={'Marketing Tools'}
          >
            {/* <Menu.Item key={'LeavesForm'}>
              <Link to={'/leaves'}>PageSpeed Insight</Link>
            </Menu.Item> */}

            <Menu.Item key={'VoiceOvers'}>
              <Link to={'/voice-overs'}>Voice Overs</Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key={'Complaint'} icon={<BugTwoTone />}>
            <Link to={'/complaint'}>Complaint Form</Link>
          </Menu.Item>

          <SubMenu
            key={'Feedbacks and Requests'}
            icon={<ProjectTwoTone />}
            title={'Feedback & Request'}
          >
            <Menu.Item key={'LeavesForm'}>
              <Link to={'/leaves'}>Leave Request</Link>
            </Menu.Item>

            <Menu.Item key={'SuggestionsComplaintForm'}>
              <Link to={'/suggestions&complaints'}>Suggestion/Complaint</Link>
            </Menu.Item>
          </SubMenu>

          {/* <SubMenu key={'ProjectMenu'} icon={<ProjectTwoTone />} title={'Project Tracking'}>
            <Menu.Item key={'Quote'}>
              <Link to={'/projecttracking'}>View Projects</Link>
            </Menu.Item>

            <Menu.Item key={'MaintenancePlan'}>
              <Link to={'/maintenance'}>Maintenance Plan</Link>
            </Menu.Item>
          </SubMenu> */}

          {/* <Menu.Item key={'Quote'} icon={<ProjectTwoTone />}>
            <Link to={'/projecttracking'} />
            Project Tracking
          </Menu.Item>
          <Menu.Item key={'MaintenancePlan'} icon={<BuildTwoTone />}>
            <Link to={'/maintenance'} />
            Maintenance Plan
          </Menu.Item> */}

          {/* <Menu.Item key={'PaymentInvoice'} icon={<CreditCardOutlined />}>
            <Link to={'/payment/invoice'} />
            Project Bidding
          </Menu.Item> */}

          {/* <SubMenu key={'ProposalMenu'} icon={<ContainerTwoTone />} title={'Bidding Proposal'}>
            <Menu.Item key={'AllProposals'}>
              <Link to={'/proposals/all-proposals'}>View Proposals</Link>
            </Menu.Item>

            <Menu.Item key={'Proposal'}>
              <Link to={'/proposal'}>Proposal Generator</Link>
            </Menu.Item>
          </SubMenu> */}

          {currentAdmin?._id === specificAdminId && (
            <Menu.Item key={'AttendanceReport'} icon={<HourglassTwoTone />}>
              <Link to={'/attendance'} />
              Activity Log
            </Menu.Item>
          )}

          {/*
          <Menu.Item key={'Employee'} icon={<ContactsTwoTone />}>
            <Link to={'/employee'} />
            Team Management
          </Menu.Item>

          <Menu.Item key={'Admin'} icon={<IdcardTwoTone />}>
            <Link to={'/admin'} />
            Admins
          </Menu.Item> */}

          {/* <SubMenu key={'Settings'} icon={<ApiTwoTone />} title={'Settings'}>
            <Menu.Item key={'SettingsPage'}>
              <Link to={'/settings'} />
              General Settings
            </Menu.Item>
            <Menu.Item key={'PaymentMode'}>
              <Link to={'/payment/mode'} />
              Payment Mode
            </Menu.Item>
            <Menu.Item key={'Role'}>
              <Link to={'/role'} />
              Role
            </Menu.Item>
          </SubMenu> */}
        </Menu>
      </Sider>
    </>
  );
}
