export const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://www.call.dymax.a2hosted.com/api/'
    : 'http://localhost:8888/api/';

export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://www.call.dymax.a2hosted.com/'
    : 'http://localhost:8888/';

export const DOWNLOAD_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://www.call.dymax.a2hosted.com/download/'
    : 'http://localhost:8888/download/';

export const ACCESS_TOKEN_NAME = 'x-auth-token';
